window.addEventListener("DOMContentLoaded", () => {
  const tabs = document.querySelectorAll('[role="tab"]');
  const tabList = document.querySelector('[role="tablist"]');

  // Nav Toggler
  const navToggle = document.getElementById('js-nav-toggle');
  const navigation = document.getElementById('js-nav')

  navToggle?.addEventListener('click', () => {
    navigation?.classList.toggle('open')
    navToggle?.classList.toggle('nav-open')
  })

  // Tab Handler 
  // Add a click event handler to each tab
  tabs.forEach((tab) => {
    tab.addEventListener("click", changeTabs);
  });

  // Enable arrow navigation between tabs in the tab list
  let tabFocus = 0;

  tabList?.addEventListener("keydown", (e) => {
    // Move right
    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
      tabs[tabFocus].setAttribute("tabindex", -1);
      if (e.key === "ArrowRight") {
        tabFocus++;
        // If we're at the end, go to the start
        if (tabFocus >= tabs.length) {
          tabFocus = 0;
        }
        // Move left
      } else if (e.key === "ArrowLeft") {
        tabFocus--;
        // If we're at the start, move to the end
        if (tabFocus < 0) {
          tabFocus = tabs.length - 1;
        }
      }

      tabs[tabFocus].setAttribute("tabindex", 0);
      tabs[tabFocus].focus();
    }
  });
});

function changeTabs(e) {
  const target = e.target;
  const parent = target.parentNode;
  const grandparent = parent.parentNode;
  const filters = document.getElementById('js-at-select');
  const filtersLabel = document.getElementById('js-at-select-label');


  if (target.id === 'tab-1' && filters) {
    filters.style.display = "inline-block";
    filtersLabel.style.display = "inline-block";
  } else {
    filters.style.display = "none";
    filtersLabel.style.display = "none";

  }


  // Remove all current selected tabs
  parent
    .querySelectorAll('[aria-selected="true"]')
    .forEach((t) => t.setAttribute("aria-selected", false));

  // Set this tab as selected
  target.setAttribute("aria-selected", true);

  // Hide all tab panels
  grandparent
    .querySelectorAll('[role="tabpanel"]')
    .forEach((p) => p.setAttribute("hidden", true));

  // Show the selected panel
  grandparent.parentNode
    .querySelector(`#${target.getAttribute("aria-controls")}`)
    .removeAttribute("hidden");

  window.dispatchEvent(new Event('resize'))
  var changeTabsEvent = new CustomEvent("tabs-changed", { "detail": "User has changed tabs, reset filters" });
  document.dispatchEvent(changeTabsEvent);
}
